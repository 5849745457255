import React from 'react';

const Languages = () => {
  return (
    <div>
      <h2>Languages</h2>
      <ul>
        <li>English</li>
        <li>Spanish</li>
        <li>French</li>
        <li>Ukrainian</li>
        <li>Russian</li>
      </ul>
    </div>
  );
}

export default Languages;